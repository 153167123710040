import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, take, tap, withLatestFrom } from 'rxjs/operators';

import { PaymentMethods } from '@libs/modules/main/pages/payment/payment-methods';
import { PaymentCommon, PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { IPaymentOption } from '@libs/shared/payment-option/payment-option';
import { ProductVariantIdentifier } from '@libs/shared/product/product-variant-identifier.enum';
import { UserCommon } from '@libs/shared/user/user.common';
import { onPaymentSuccess } from '@libs/store/analytics/actions';
import { IApplicationState } from '@libs/store/application-state';
import { BoostActions, BoostSelectors } from '@libs/store/boost';
import { BoostPackagesActions } from '@libs/store/boost-packages';
import { BoostProductActions, BoostProductSelectors } from '@libs/store/boost-product';
import { ConversationActions } from '@libs/store/conversations';
import { MembershipActions } from '@libs/store/membership';
import { MessageActions } from '@libs/store/messages';
import { PaymentActions, PaymentSelectors } from '@libs/store/payment';
import { IPaymentInfo, PaymentInfoActions, PaymentInfoSelectors } from '@libs/store/payment-info';
import { IAccessToCheckoutAction } from '@libs/store/payment-info/interfaces/access-to-checkout-payload.interface';
import { IPaymentProvider } from '@libs/store/payment/interfaces/payment-provider';
import { selectAllPaymentOption } from '@libs/store/payment/selectors';
import { TrialSelectors } from '@libs/store/trial';
import { UpgradeAccountScreenActions } from '@libs/store/upgrade-account';
import { UPGRADE_ACCOUNT_VERSIONS } from '@libs/store/upgrade-account/types/upgrade-account-version.type';
import { exponentialBackoff } from '@libs/utils/observable-helpers/observable-helpers';
import { HAS_ACCESSED_FORBIDDEN_CHECKOUT_QUERY_PARAM_NAME } from '@meupatrocinio/effects/payment/constants/constants';
import { ABTestsLoaderService } from '@meupatrocinio/modules/ab-tests/services/ab-tests-loader/ab-tests-loader.service';
import { PaymentInfoService } from '@meupatrocinio/modules/main/services/payment/payment-info.service';
import { ProductRouteInfoService } from '@meupatrocinio/modules/payment-v2/product-route-info/product-route-info.service';
import { PaymentOptionsLoaderService } from '@meupatrocinio/modules/upgrade-account/services/payment-options-loader.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { BoostProductService } from '@meupatrocinio/services/boost-product/boost-product.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';
import { TrialService } from '@meupatrocinio/services/trial/trial.service';

@Injectable()
export class PaymentInfoEffects {
  private readonly RETRY_ACTION_DELAY = 1000 as const;

  fetchPaymentHistory$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.fetchPaymentHistory),
        withLatestFrom(this.store.pipe(select(PaymentInfoSelectors.selectIsPaying))),
        filter(([_, isPaying]): boolean => !isPaying),
        concatMap((): Observable<Action> => {
          return this.paymentInfoService.getPaymentHistory().pipe(
            concatMap((response: IAuthResponse) => {
              if (response.data.payments.length === 0) {
                return EMPTY;
              }

              return of(
                PaymentInfoActions.upsertPaymentInfo({
                  paymentInfo: response.data.payments,
                }),
              );
            }),
            catchError((): Observable<Action> => EMPTY),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  handleUpdatedResponse$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.handleUpdatedResponse),
        withLatestFrom(this.store.pipe(select(BoostProductSelectors.selectProductUuid))),
        concatMap(([{ updatedPaymentInfo }, boostProductUuid]): Observable<Action> => {
          return this.handleUpdatedPaymentResponse$(updatedPaymentInfo, boostProductUuid);
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  onSuccessfullyPaymentDone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.setPaymentStatus),
        filter(({ paymentStatus }): boolean => this.paymentInfoService.isPaymentOk(paymentStatus)),
        map((): Action => {
          this.store.dispatch(ConversationActions.cleanConversations());
          this.store.dispatch(MessageActions.cleanMessages());

          return MembershipActions.latestPaidMembershipChange();
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handlePrizeProcessingMessage$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.setPaymentStatus),
        filter(({ paymentStatus }): boolean => this.paymentInfoService.isPaymentOk(paymentStatus)),
        withLatestFrom(
          this.store.pipe(select(PaymentSelectors.selectCurrentPayment)),
          this.store.pipe(select(BoostProductSelectors.selectProductUuid)),
        ),
        concatMap(([_, currentPayment, boostProductUuid]) => {
          if (this.boostProductService.isBoostPackagePayment(currentPayment.paymentInfo, boostProductUuid)) {
            return EMPTY;
          }

          return of(
            PaymentInfoActions.updateUserAndLoadBoostPackages({
              latestPayment: currentPayment.paymentInfo,
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  updateUserAndLoadBoostPackages$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.updateUserAndLoadBoostPackages),
        concatMap((action): Observable<Action> => {
          return this.profileService.getSelf$().pipe(
            exponentialBackoff(),
            concatMap((user: UserCommon): Observable<Action> => {
              if (MembershipCommon.isFree(user.membership_type_id)) {
                return of(action).pipe(delay(2000));
              }

              if (PaymentCommon.isPaymentForAPlusPackage(action.latestPayment)) {
                this.store.dispatch(
                  BoostActions.setIsProcessingBoostPrize({
                    isProcessing: true,
                  }),
                );
                this.store.dispatch(BoostActions.checkPendingCredits());
              }

              return of(BoostPackagesActions.loadBoostPackages());
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  resetIsProcessingBoostPrizeOnLogin$ = createEffect(
    () =>
      this.authenticationService.onLogin$.pipe(
        withLatestFrom(this.store.pipe(select(BoostSelectors.selectHasBalance))),
        filter(([_, hasBalance]) => hasBalance),
        map(() => {
          return BoostActions.setIsProcessingBoostPrize({
            isProcessing: false,
          });
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  loadPaymentProvider$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.loadPaymentProvider),
        concatMap(() =>
          this.paymentInfoService.loadPaymentProvider().pipe(
            exponentialBackoff(),
            tap({
              next: (response: IAuthResponse<IPaymentProvider>) => {
                if (
                  response.data !== undefined &&
                  response.data.provider !== '' &&
                  response.data.payment_types !== undefined
                ) {
                  this.paymentInfoService.setProvider(response.data.provider ?? PaymentCommon.PROVIDER_PAGSEGURO);
                  this.paymentInfoService.setPaymentTypesAvailable(
                    response.data.payment_types ?? [PaymentMethods.PAYMENT_CREDIT_CARD, PaymentMethods.PAYMENT_BOLETO],
                  );

                  return;
                }

                this.paymentInfoService.setDefaultPaymentConfiguration();
              },
            }),
            catchError(() => {
              this.paymentInfoService.setDefaultPaymentConfiguration();

              return EMPTY;
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  handleAccessToCheckout$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.handleAccessToCheckout),
        withLatestFrom(this.store.pipe(select(selectAllPaymentOption))),
        concatMap(([action, paymentOptions]: [IAccessToCheckoutAction, IPaymentOption[]]): Observable<Action> => {
          if (action.isOffer) {
            return of(UpgradeAccountScreenActions.handleFetchStandardPaymentOptions());
          }

          combineLatest([
            this.abTestsLoaderService.getNewPlansFreeUsersTreatment$(),
            this.abTestsLoaderService.getNewPlansExpiredUsersTreatment$(),
          ])
            .pipe(
              take(1),
              map(([newPlansFreeUsersTreatment, newPlansExpiredUsersTreatment]) => {
                if (newPlansFreeUsersTreatment || newPlansExpiredUsersTreatment) {
                  this.store.dispatch(
                    UpgradeAccountScreenActions.handleUpgradeAccountAccess({
                      isOffer: false,
                    }),
                  );
                }
              }),
            )
            .subscribe();

          if (this.isPremiumWithPrize(action.variantUuid)) {
            this.dispatchLoadingNewPlanActions();

            return of(PaymentActions.checkCoreDataAvailability());
          }

          if (this.isExpressApprovalProduct(action.variantUuid)) {
            return of(
              PaymentInfoActions.setHasResolvedPlans({
                hasResolvedPlans: true,
              }),
            );
          }

          if (!paymentOptions.length) {
            this.store.dispatch(PaymentActions.fetchPaymentOptions());

            return of(action).pipe(delay(this.RETRY_ACTION_DELAY));
          }

          if (this.trialService.isTrialProduct(action.variantUuid)) {
            return of(PaymentInfoActions.handleAccessToTrialCheckout());
          }

          return of(
            PaymentInfoActions.navigateAfterResolvingPlans({
              planPath: action.planPath,
              variantUuid: action.variantUuid,
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handleAccessToTrialCheckout$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.handleAccessToTrialCheckout),
        withLatestFrom(this.store.pipe(select(TrialSelectors.selectIds))),
        concatMap(([action, trialIds]: [Action, string[] | number[]]): Observable<Action> => {
          if (!trialIds.length) {
            return of(action).pipe(delay(this.RETRY_ACTION_DELAY));
          }

          return of(
            PaymentInfoActions.setHasResolvedPlans({
              hasResolvedPlans: true,
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  navigateAfterResolvingPlans$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.navigateAfterResolvingPlans),
        withLatestFrom(this.store.pipe(select(selectAllPaymentOption))),
        concatMap(([action, paymentOptions]) => {
          if (!this.isValidPlanPath(action.planPath, paymentOptions)) {
            this.router.navigate(['main', 'upgrade-account'], {
              queryParamsHandling: 'merge',
              queryParams: {
                [HAS_ACCESSED_FORBIDDEN_CHECKOUT_QUERY_PARAM_NAME]: 1,
              },
            });

            return EMPTY;
          }

          return of(
            PaymentInfoActions.setHasResolvedPlans({
              hasResolvedPlans: true,
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  resetHasResolvedPlansOnLogin$: Observable<void> = createEffect(
    (): Observable<void> =>
      this.authenticationService.onLogin$.pipe(
        tap({
          next: () => {
            this.store.dispatch(
              PaymentInfoActions.setHasResolvedPlans({
                hasResolvedPlans: false,
              }),
            );
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  checkCoreDataAvailability$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentActions.checkCoreDataAvailability),
        withLatestFrom(this.store.pipe(select('user')), this.store.pipe(select('stats'))),
        concatMap(([action, user, stats]): Observable<Action> => {
          if (
            !user.sex ||
            !user.membership_type_id ||
            stats.purchasedDays === null ||
            stats.purchasedDays === undefined
          ) {
            return of(action).pipe(delay(this.RETRY_ACTION_DELAY));
          }

          return of(PaymentActions.checkUserEligibilityToAPlusCheckout());
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  checkUserEligibilityToAPlusCheckout$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentActions.checkUserEligibilityToAPlusCheckout),
        withLatestFrom(this.store.pipe(select('user'))),
        concatMap(([_, user]): Observable<Action> => {
          const isEligible = this.paymentOptionsLoaderService.isEligibleToAPlusCheckout(user);

          if (!isEligible) {
            this.router.navigate(['main', 'upgrade-account']);

            return EMPTY;
          }

          return of(
            PaymentActions.fetchPlanByUuid({
              uuid: ProductVariantIdentifier.DADDY_PREMIUM_PLUS_1_MONTH,
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  fetchPlanByUuid$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentActions.fetchPlanByUuid),
        concatMap(({ uuid }): Observable<Action> => {
          return this.paymentOptionsLoaderService.fetchPlanByUuid$(uuid).pipe(
            exponentialBackoff(),
            concatMap((response: IAuthResponse): Observable<Action> => {
              const paymentOption: IPaymentOption = response.data;

              if (!paymentOption.price) {
                throw new Error(`No data in response for ${uuid}`);
              }

              this.store.dispatch(
                PaymentActions.updatePaymentOptions({
                  paymentOptions: [paymentOption],
                }),
              );

              return of(
                UpgradeAccountScreenActions.resolveLoadingAfterFetchPaymentOptions({
                  version: UPGRADE_ACCOUNT_VERSIONS.premiumWithPrize,
                }),
              );
            }),
            catchError((): Observable<Action> => {
              this.openModalServiceWithErrorMessage();

              return EMPTY;
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  constructor(
    protected actions$: Actions,
    protected paymentInfoService: PaymentInfoService,
    protected store: Store<IApplicationState>,
    protected modalService: ModalService,
    protected translateService: TranslateService,
    protected profileService: ProfileService,
    protected authenticationService: AuthenticationService,
    protected router: Router,
    protected paymentOptionsLoaderService: PaymentOptionsLoaderService,
    protected boostProductService: BoostProductService,
    protected trialService: TrialService,
    protected productRouteInfoService: ProductRouteInfoService,
    protected abTestsLoaderService: ABTestsLoaderService,
  ) {
    //
  }

  public isValidPlanPath(planPath: string, paymentOptions: IPaymentOption[]) {
    return (
      paymentOptions.find(
        (paymentOption: IPaymentOption) =>
          paymentOption.uuid === this.productRouteInfoService.getVariantUuidByPath(planPath) ||
          paymentOption.uuid === this.productRouteInfoService.getNewProductVariantUuidByPath(planPath),
      ) !== undefined
    );
  }

  private isPremiumWithPrize(variantUuid: string) {
    return variantUuid === ProductVariantIdentifier.DADDY_PREMIUM_PLUS_1_MONTH;
  }

  private dispatchLoadingNewPlanActions() {
    this.store.dispatch(
      UpgradeAccountScreenActions.setActiveVersion({
        version: UPGRADE_ACCOUNT_VERSIONS.premiumWithPrize,
      }),
    );
    this.store.dispatch(
      UpgradeAccountScreenActions.setIsResolvingVisiblePlans({
        isResolvingVisiblePlans: true,
      }),
    );
  }

  private openModalServiceWithErrorMessage() {
    this.modalService.open(
      this.translateService.instant('modules.main.pages.payment.campaign_checkout.network_error'),
      () => {
        this.router.navigate(['main', 'home']);
      },
    );
  }

  private isExpressApprovalProduct(variantUuid: string) {
    return variantUuid === ProductVariantIdentifier.EXPRESS_APPROVAL;
  }

  private handleUpdatedPaymentResponse$(
    updatedPaymentInfo: IPaymentInfo,
    boostProductUuid: string,
  ): Observable<Action | never> {
    this.store.dispatch(
      PaymentInfoActions.setIsPaying({
        isPaying: false,
      }),
    );

    if (this.paymentInfoService.isPaymentInfoStatusFailed(updatedPaymentInfo.status)) {
      return this.handleFailedPayment(updatedPaymentInfo);
    }

    if (this.paymentInfoService.isPaymentInfoStatusApproval(updatedPaymentInfo.status)) {
      return this.handleApprovedPayment(updatedPaymentInfo, boostProductUuid);
    }

    return EMPTY;
  }

  private handleFailedPayment(updatedPaymentInfo: IPaymentInfo): Observable<Action> {
    this.store.dispatch(BoostProductActions.checkIfIsBoostPayment({ updatedPaymentInfo }));
    this.store.dispatch(
      PaymentInfoActions.setPaymentStatus({
        paymentStatus: PaymentStatus.PAYMENT_ERROR,
      }),
    );

    return of(
      PaymentInfoActions.handlePaymentErrorStatus({
        price: updatedPaymentInfo.subtotal_amount,
      }),
    );
  }

  private handleApprovedPayment(updatedPaymentInfo: IPaymentInfo, boostProductUuid: string): Observable<Action> {
    this.store.dispatch(BoostProductActions.checkIfIsBoostPayment({ updatedPaymentInfo }));
    this.store.dispatch(
      onPaymentSuccess({
        paymentInfo: updatedPaymentInfo,
      }),
    );

    if (!this.boostProductService.isBoostPackagePayment(updatedPaymentInfo, boostProductUuid)) {
      this.store.dispatch(PaymentActions.fetchPaymentOptions());
      this.profileService.updateSelf();
      this.store.dispatch(PaymentInfoActions.navigateAfterPayment());
    }

    if (updatedPaymentInfo.variant_uuid === ProductVariantIdentifier.EXPRESS_APPROVAL) {
      return of(
        PaymentInfoActions.setPaymentStatus({
          paymentStatus: PaymentStatus.PAYMENT_NONE,
        }),
      );
    }

    return of(
      PaymentInfoActions.setPaymentStatus({
        paymentStatus: PaymentStatus.PAYMENT_OK,
      }),
    );
  }
}
