import { Component, Input, OnInit } from '@angular/core';
import { PixStateFacadeService } from '@libs/modules/payment-v2/services/pix-state-facade.service';
import { UuidGenerator } from '@libs/utils/uuid-generator/uuid-generator';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ProviderSwitchActions } from '@meupatrocinio/modules/payment-v2/provider-switch/actions';
import { IResolvedProductRouteInfo } from '@meupatrocinio/resolvers/payment/interfaces/resolved-product-route-info.interface';
import { PaymentInfoActions } from '@libs/store/payment-info';

@Component({
  selector: 'mp-form-pix-payment-v2',
  templateUrl: './form-pix-payment-v2.component.html',
})
export class PixPaymentFormV2Component implements OnInit {
  public copyCode$: Observable<string> = of('');
  public shouldHideQrCode$: Observable<boolean> = of(false);
  public qrCode$: Observable<string> = of('');
  public qrCodeGenerationTimestamp$: Observable<number> = of(0);
  public isLoadingPixQrCode$: Observable<boolean> = of(false);

  @Input() product!: IResolvedProductRouteInfo;

  constructor(
    protected pixStateFacadeService: PixStateFacadeService,
    protected store: Store,
  ) {
    //
  }

  ngOnInit(): void {
    this.isLoadingPixQrCode$ = this.pixStateFacadeService.isPaying$;
    this.copyCode$ = this.pixStateFacadeService.copyCode$;
    this.shouldHideQrCode$ = this.pixStateFacadeService.shouldHideQrCode$;
    this.qrCode$ = this.pixStateFacadeService.qrCode$;
    this.qrCodeGenerationTimestamp$ = this.pixStateFacadeService.qrCodeGenerationTimestamp$;
  }

  public canShowPixPaymentOptionContent(copyCode: string, shouldHideQrCode: boolean) {
    return copyCode === '' && !shouldHideQrCode;
  }

  public canRenderQrCodeCountdown(qrCode: string, copyCode: string) {
    return qrCode !== '' && copyCode !== '';
  }

  public canShowPixQrCodeResult(qrCode: string, copyCode: string, shouldHideQrCode: boolean) {
    return this.canRenderQrCodeCountdown(qrCode, copyCode) || this.canRenderQrCodeExpiredResult(shouldHideQrCode);
  }

  public resetPixData() {
    this.store.dispatch(PaymentInfoActions.resetPixData());
  }

  public makePixPayment(cpf: string) {
    if (!this.product) {
      return;
    }

    const idempotencyKey = UuidGenerator.generate();

    this.store.dispatch(
      ProviderSwitchActions.handlePixPayment({
        paymentData: {
          taxId: cpf,
          product: {
            product_uuid: this.product.productUuid,
            variant_uuid: this.product.variantUuid,
          },
          idempotencyKey,
        },
      }),
    );
  }

  private canRenderQrCodeExpiredResult(shouldHideQrCode: boolean) {
    return shouldHideQrCode;
  }
}
