/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

////////////////////////////////////////////////////////////////////////////
////                                                                    ////
////             THIS FILE HAS BEEN GENERATED AUTOMAGICALLY             ////
////                                                                    ////
////////////////////////////////////////////////////////////////////////////

export const Config = {
    "branch": "main",
    "version": "v1.31.0.dev.754",
    "name": "stage",
    "mailDomain": "mbeta.info",
    "mailPrefix": "payer_",
    "v2URL": "https://app.mbeta.info/",
    "appURL": "https://beta.meupat.com/",
    "serverIp": "https://api.mbeta.info/",
    "realtimeServerIp": "https://notifications.mbeta.info/",
    "imagesServer": "https://images.mbeta.info/",
    "emailLinksDomain": "links.mbeta.info",
    "emailLinksDomainAndroid": "https://dating.mbeta.info",
    "listsTTL": 600000,
    "payment": {
        "server": "https://test.oppwa.com/v1/",
        "token": "OGE4Mjk0MTg0NzNmMDg5MTAxNDczZjhjYWFlYzAxMzF8M1hSampXUjI=",
        "entityId": "8a829418473f979601473f9befa5000a",
        "currency": "BRL",
        "boletoStateCode": "SP",
        "boletoState": "São Paulo",
        "boletoCity": "São Paulo",
        "boletoStreet": "Av. Paulista, 1636 - cj 706, Bela Vista",
        "boletoCEP": "01310-200",
        "boletoStreetNumber": "1636",
        "boletoComplement": "cj 706",
        "boletoDistrict": "Bela Vista",
        "boletoStreetOnly": "Av. Paulista",
        "publicKey": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1hRKyFt90bStb6cbXFUN\n+UozHwCyPD8Nr3WhFh36W3LXcO1GZkdbfp8HqBN+wsc5cmHmZ1wEu0zGlRVAk4d7\nTq37t67ibopf1jADcfK9FATfJXIwFnq5i98lycGBVNIqEm85Lrv8vhYbXC73hF38\n8qhWh2tIylZuyXxS8BFk2FDC2/fYmyLiLj3nWT4cM59h5nJ7hMYllMMBa0gVqCf5\npmVLJRdRWP1EpjOrtRRndiwPwcanBYqAnB+y3HE57f8xuUhpBHImneWjnC14Grxe\n54rTzDlNqGjLlXPEZAsmNVNE4kPbjUULRRELHbT37KsEijfPfnOZO81w2bNT1E25\ntwIDAQAB\n-----END PUBLIC KEY-----"
    },
    "paymentBrands": [
        "VISA",
        "MASTER",
        "AMEX",
        "ELO"
    ],
    "gameTrial": {
        "enableDeactivateGame": true,
        "enableMessageGame": false
    },
    "enablePushv2": true,
    "development": true,
    "showLogs": {
        "downloads": true,
        "device": true,
        "notifications": true,
        "routing": true,
        "redux": true,
        "fireBase": true,
        "location": true,
        "keyboard": true,
        "images": true
    },
    "throttledResponse": 403,
    "GOOGLE_TAG_MANAGER_ID": "GTM-T62CB7P",
    "gpsEnabled": false,
    "translationEnabled": true,
    "defaultPlanDurationIndex": 2,
    "feedbackNagbarPatchMinVersion": 30,
    "isAgeVisibleAvailable": false,
    "isLocationVisibleAvailable": false,
    "isPremiumSettingsAvailable": true,
    "isReferralAvailableOnAndroid": false,
    "isPhotoVerifyAvailableOnAndroid": false,
    "referralFriendshipDayEnabled": false,
    "verifyPhotoModalTimeLapse": 300000,
    "mpUrlContact": "https://www.meupatrocinio.com/contato",
    "supportLink": "https://suporte.meupatrocinio.com/hc/pt-br?search=",
    "installmentsPercentage": 30,
    "hideSpanishOption": false,
    "billingAddressInExpressApproval": true,
    "cepIntegrationEndPoint": "https://viacep.com.br",
    "imagesFolderBaseUrl": "/assets/img/",
    "imagesCdnUrl": "https://cdn.meupatrocinio.com/assets/app/",
    "pagseguroUrlScript": "https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js",
    "reduxName": "mp-redux-v39",
    "typeFormUrl": "https://ekpvtwna6be.typeform.com/denunciaMP#",
    "boostBFFUrl": "https://api.mbeta.info/boost-bff",
    "splitIOAuthorizationKey": "pe59au2d00qjf2u3pvcpkg2c3orvubsrv9o6",
    "areABTestsEnabled": true,
    "isBoostEnabled": true,
    "isBoostForBabiesEnabled": true,
    "isBoostPaymentEnabled": true,
    "refreshButtonMilliseconds": 300000,
    "pagseguro": {
        "publicKey": "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB"
    },
    "isDatadogEnabled": false,
    "datadog": {
        "applicationId": "8a829418473f979601473f9befa5000a",
        "clientToken": "pubab1cb62dd452f3311dc1d25bef7eeeea",
        "site": "datadoghq.com",
        "service": "meupatrocinio-stage",
        "env": "stage",
        "sessionSampleRate": 0,
        "sessionReplaySampleRate": 0
    },
    "netcore": {
        "websiteIdentifier": "389bd0f51e0fa6a53b43dc9fb125d6ed",
        "panelIdentifier": "ADGMOT35CHFLVDHBJNIG50K96BVCO5ND5OJD9ARS6JF1S3PV60E0",
        "cdnLink": "//cdnt.netcoresmartech.com/smartechclient.js",
        "lists": {
            "allUsers": 4
        }
    },
    "enableProfileSwipe": false,
    "isSearchV2Enabled": true,
    "isPixPaymentEnabledMembership": true,
    "isPixPaymentEnabledExpressApproval": true,
    "adjust": {
        "appToken": "zmhg9ggwsoow",
        "environment": "sandbox",
        "logLevel": "verbose"
    },
    "isRegV5Enabled": true,
    "is15DaysTrialEnabled": true,
    "isNetCoreEnabled": true,
    "isWhatsappEnabled": false
};