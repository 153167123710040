import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { UserCommon } from '@libs/shared/user/user.common';
import { UserProfileSelectors } from '@libs/store/authentication';
import { NotificationsSelectors } from '@libs/store/notifications/selectors';
import { Config } from '@meupatrocinio/config';
import { NetcoreActions } from '@meupatrocinio/infra/netcore/actions/netcore.actions';
import { NetcoreService } from '@meupatrocinio/infra/netcore/services/netcore.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { GlobalObjectService } from '@meupatrocinio/services/global-object-service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class NetcoreEffects {
  private renderer2: Renderer2;

  initialize$ = createEffect(
    () =>
      this.authenticationService.onLogin$.pipe(
        withLatestFrom(this.store.select(UserProfileSelectors.selectUser)),
        filter(([_, user]) => Config.isNetCoreEnabled && this.isUserEligibleForInjectNetcoreScript(user)),
        tap({
          next: () => {
            if (!this.netcoreService.isNetCoreAlreadyInjected()) {
              this.netcoreService.injectScript(this.renderer2);
            }

            this.netcoreService.dispatchInitialsScript();
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  removeScript$ = createEffect(
    () =>
      this.authenticationService.onLogout$.pipe(
        tap({
          next: () => this.netcoreService.removeCDNScript(this.renderer2),
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  handleAllPlansView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NetcoreActions.handleAllPlansViewEvent),
        withLatestFrom(...this.getCoreData()),
        tap({
          next: ([_, { profile_id, email_verified }, { email }]) => {
            this.netcoreService.dispatchCustomEvent('ALL_PLANS_VIEW', {
              user_id: profile_id,
              email_verified,
              unsubscribed_email_marketing: email ? 'TRUE' : 'FALSE',
            });
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  handlePlanView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NetcoreActions.handlePlanView),
        withLatestFrom(...this.getCoreData()),
        tap({
          next: ([{ price }, { profile_id, email_verified }, { email }]) => {
            const routeByPlan = this.globalObjectService.window.location.href.split('?')[0];

            this.netcoreService.dispatchCustomEvent('PLAN_VIEW', {
              currency: 'BRL',
              plan_url: routeByPlan,
              price,
              user_id: profile_id,
              email_verified,
              unsubscribed_email_marketing: email ? 'TRUE' : 'FALSE',
            });
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  handleSubscriptionPurchase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NetcoreActions.handleSubscriptionPurchase),
        withLatestFrom(...this.getCoreData()),
        tap({
          next: ([{ price, paymentMethod }, { profile_id, email_verified }, { email }]) => {
            const routeByPlan = this.globalObjectService.window.location.href.split('?')[0];

            this.netcoreService.dispatchCustomEvent('SUBSCRIPTION_PURCHASE', {
              email_verified,
              unsubscribed_email_marketing: email ? 'TRUE' : 'FALSE',
              plan_url: routeByPlan,
              price,
              user_id: profile_id,
              payment_method: paymentMethod,
            });
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  handleFavoriteProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NetcoreActions.handleFavoriteProfile),
        withLatestFrom(...this.getCoreData()),
        tap({
          next: ([{ profileId }, { profile_id, email_verified }, { email }]) => {
            this.netcoreService.dispatchCustomEvent('FAVORITE_PROFILE', {
              favorite_profile_id: profileId,
              email_verified,
              unsubscribed_email_marketing: email ? 'TRUE' : 'FALSE',
              user_id: profile_id,
            });
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  constructor(
    protected actions$: Actions,
    protected netcoreService: NetcoreService,
    protected store: Store,
    protected authenticationService: AuthenticationService,
    protected rendererFactory: RendererFactory2,
    protected globalObjectService: GlobalObjectService,
  ) {
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
  }

  private isUserEligibleForInjectNetcoreScript(user: UserCommon) {
    return user !== undefined && user.profile_id !== 0 && user?.status === UserCommon.STATUS_ACTIVE;
  }

  private getCoreData() {
    return [
      this.store.select(UserProfileSelectors.selectUser),
      this.store.select(NotificationsSelectors.notificationsMarketing),
    ];
  }
}
