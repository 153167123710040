import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { SubscribeButtonComponentCommon } from '@libs/components/subscribe-button/subscribe-button.component.common';
import { ABTestsLoaderService } from '@meupatrocinio/modules/ab-tests/services/ab-tests-loader/ab-tests-loader.service';

import { PaymentInfoService } from '@meupatrocinio/modules/main/services/payment/payment-info.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'mp-subscribe-button',
  templateUrl: 'subscribe-button.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SubscribeButtonComponent extends SubscribeButtonComponentCommon {
  constructor(
    protected authenticationService: AuthenticationService,
    public paymentInfoService: PaymentInfoService,
    protected abTestsLoaderService: ABTestsLoaderService,
    protected router: Router,
  ) {
    super(authenticationService, paymentInfoService);
  }

  public getSubscribeText() {
    if (this.isDaddyMommyFree() || this.isBabyFree()) {
      return 'common.subscribe';
    }

    return 'common.upgrade';
  }

  public navigateToUpgradeAccount() {
    combineLatest([
      this.abTestsLoaderService.getNewPlansFreeUsersTreatment$(),
      this.abTestsLoaderService.getNewPlansExpiredUsersTreatment$(),
    ])
      .pipe(
        take(1),
        map(([isTargetFree, isTargetExpired]) => {
          if (isTargetFree || isTargetExpired) {
            return this.router.navigate(['/main', 'upgrade-account'], { replaceUrl: true });
          }

          return this.router.navigate(['/main', 'upgrade-account'], {
            queryParams: { subscription: true },
            replaceUrl: true,
          });
        }),
      )
      .subscribe();
  }
}
