import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  BLURHASH_DEFAULT_QR_CODE,
  RANDOM_PROFILE_PLACEHOLDERS,
} from '@meupatrocinio/utils/components/blurhash-image/constants';
import { decode } from 'blurhash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-blurhash-image',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './blurhash-image.html',
  styleUrls: ['./blurhash-image.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlurhashImageComponent implements OnInit, OnChanges {
  @Input() width: number | string = '100%';
  @Input() height: number | string = '100%';
  @Input() imageUrl!: string;
  @Input() hash?: string;
  @Input() isQrCode = false;

  @ViewChild('canvas') canvasRef!: ElementRef<HTMLCanvasElement>;

  imageLoaded = new BehaviorSubject(false);

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.renderBlurhash();
  }

  private hashToDecode!: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.hashToDecode = this.hash || this.getRandomBlurhashPlaceholder();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.imageUrl) {
      this.imageLoaded.next(false);
    }
  }

  ngAfterViewInit() {
    this.renderBlurhash();
  }

  renderBlurhash(): void {
    if (!this.canvasRef?.nativeElement) {
      return;
    }

    const canvas = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');

    this.updateCanvas(canvas, ctx, Math.ceil(canvas.width), Math.ceil(canvas.height));
  }

  private updateCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, width: number, height: number): void {
    const pixels = decode(this.hashToDecode, width, height);
    const imageData = new ImageData(new Uint8ClampedArray(pixels), width, height);

    canvas.width = width;
    canvas.height = height;

    ctx.putImageData(imageData, 0, 0);
    this.cdr.markForCheck();
  }

  private getRandomBlurhashPlaceholder(): string {
    if (this.isQrCode) {
      return BLURHASH_DEFAULT_QR_CODE;
    }

    const randomIndex = Math.floor(Math.random() * RANDOM_PROFILE_PLACEHOLDERS.length);
    return RANDOM_PROFILE_PLACEHOLDERS[randomIndex];
  }

  onImageLoad() {
    this.imageLoaded.next(true);
  }
}
