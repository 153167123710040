<mp-actionbar>
    <mp-actionbar-back-title [title]="productDescription"></mp-actionbar-back-title>
</mp-actionbar>
<main>
    <ng-container *ngIf="!(shouldHideQrCode$ | async); else expirationWarning">
        <form
            class="page-form payment-form"
            (ngSubmit)="onSubmit()"
            #cpfForm="ngForm"
        >
            <div class="form-field feedback">
                <label for="cpf">
                    {{ 'modules.main.pages.payment.cpf' | translate }}
                </label>
                <input
                    [(ngModel)]="cpf"
                    type="text"
                    name="cpf"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    id="cpf"
                >
            </div>
            @if (!canShowPaymentContent(qrCode$ | async, copyCode$ | async)) {
                <mp-submit-button
                    [text]="'modules.main.pages.payment.generate_qr_code' | translate"
                ></mp-submit-button>
            }
            <div
                class="payment-pix-content"
                *ngIf="canShowPaymentContent(qrCode$ | async, copyCode$ | async)"
            >
                <div class="payment-pix-content__qr-code-container">
                    <img
                        class="payment-pix-content__qr-code" [src]="qrCode$ | async" 
                        alt="QR Code"
                    >
                </div>
                <div class="form-group">
                    <button
                        *ngIf="copyCode$ | async as copyCode"
                        class="btn btn-copy-code"
                        type="button"
                        (click)="copyToClipboard(copyCode)"
                    >
                        {{ 'modules.main.pages.payment.pix.copy_to_clipboard' | translate }}
                        <span
                            class="material-icons"
                            aria-hidden="true"
                            [innerHTML]="icon"
                        >
                        </span>
                    </button>
                    <p class="payment-pix-content__warning">
                        {{ 'modules.main.pages.payment.pix.expiration_warning' | translate }}
                    </p>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-template #expirationWarning>
        <div class="payment-pix-content__expired">
            <mp-advanced-translate key="modules.main.pages.payment.pix.expired_warning">
                <a class="bold" [routerLink]="['/main', 'upgrade-account']" mp-translate-param="link">
                    {{ 'modules.main.pages.payment.pix.expired_warning_link_text' | translate }}
                </a>
            </mp-advanced-translate>
        </div>
    </ng-template>
</main>
<div
    class="modal modal-loading" 
    *ngIf="isLoading$ | async"
>
    <mp-loading></mp-loading>
</div>
