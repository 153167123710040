import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { PaymentMethods } from '@libs/modules/main/pages/payment/payment-methods';
import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import { PixStateFacadeService } from '@libs/modules/payment-v2/services/pix-state-facade.service';
import { MembershipCommon, MembershipType } from '@libs/shared/membership/membership.common';
import { ProductIdentifier } from '@libs/shared/product/product-identifier.enum';
import { Trial } from '@libs/shared/trial/trial';
import { UserProfileSelectors } from '@libs/store/authentication';
import { UiSelectors } from '@libs/store/new-ui';
import { PaymentInfoActions, PaymentInfoSelectors } from '@libs/store/payment-info';
import { selectOptionsByMembershipAndPeriod, selectPlanPrizeByUuid } from '@libs/store/payment/selectors';
import { TrialActions } from '@libs/store/trial';
import { ScreenSizeHelpers } from '@libs/utils/screen-size-helpers/screen-size-helpers';

import { Config } from '@meupatrocinio/config';
import { NetcoreActions } from '@meupatrocinio/infra/netcore/actions/netcore.actions';
import { ABTestsLoaderService } from '@meupatrocinio/modules/ab-tests/services/ab-tests-loader/ab-tests-loader.service';
import { ScriptLoaderService } from '@meupatrocinio/modules/shared/script-dependencies/script-loader.service';
import { IResolvedProductRouteInfo } from '@meupatrocinio/resolvers/payment/interfaces/resolved-product-route-info.interface';
import { TrialService } from '@meupatrocinio/services/trial/trial.service';

@Component({
  selector: 'mp-payment-v2',
  templateUrl: './payment-v2.html',
  styleUrls: ['payment-v2.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentV2Component implements OnInit, OnDestroy {
  public price: Observable<number> = of(0);
  public installments = 0;
  public currentPaymentMethod: string = PaymentMethods.PAYMENT_CREDIT_CARD;
  public prizeUuid: Observable<string> = of('');
  public product!: IResolvedProductRouteInfo;
  public isPixPaymentEnabledMembership: boolean = Config.isPixPaymentEnabledMembership;
  public isPixPaymentEnabledExpressApproval: boolean = Config.isPixPaymentEnabledExpressApproval;
  public hasPixTest$: Observable<boolean> = of(false);
  public hasResolvedPlans$: Observable<boolean> = this.store.pipe(select(PaymentInfoSelectors.selectHasResolvedPlans));
  public hasLoadedPaymentProvider$ = this.store.pipe(select(UiSelectors.selectHasLoadedPaymentProvider));
  public user$ = this.store.pipe(select(UserProfileSelectors.selectUser));

  public readonly BILL_DESCRIPTION = PaymentCommon.PAYMENT_BILL_DESCRIPTION_MP;
  protected readonly EXPRESS_APPROVAL_PRICE: number = 249;

  protected subscriptions: Subscription[] = [];

  constructor(
    protected route: ActivatedRoute,
    protected store: Store,
    protected translateService: TranslateService,
    protected trialService: TrialService,
    protected pixStateFacadeService: PixStateFacadeService,
    protected abTestLoaderService: ABTestsLoaderService,
    protected scriptLoaderService: ScriptLoaderService,
  ) {
    //
  }

  ngOnInit() {
    this.receiveProductFromRoute();

    this.hasPixTest$ = this.pixStateFacadeService.hasPixTest$;
    this.loadScriptPagseguro();
  }

  ngOnDestroy() {
    this.clearSubscriptions();
    this.handleRemoveGamefyTrial();
  }

  public hasResolvedCPFABTestValue(treatment: boolean | null) {
    return treatment !== null;
  }

  public getContainerModifierClass() {
    if (this.isExpressApproval()) {
      return 'payment-v2__container--centralize';
    }

    return '';
  }

  protected receiveProductFromRoute() {
    this.subscriptions.push(
      this.route.data.subscribe({
        next: (routeData: Data) => {
          this.handleProductReceivedFromRoute(routeData);
        },
      }),
    );
  }

  protected handleProductReceivedFromRoute(routeData: Data) {
    if (!('product' in routeData)) {
      throw new Error('Product data has not been resolved.');
    }

    const product: IResolvedProductRouteInfo = routeData.product;

    this.product = product;
    this.dispatchHandleAccessToCheckout(product.planPath, product.variantUuid);
    this.assignPrizeUuid();
    this.setPrice();
    this.handleViewPlansCustomEventNetcore();
  }

  protected handleViewPlansCustomEventNetcore() {
    this.subscriptions.push(
      this.price
        .pipe(
          filter((price) => price !== 0),
          map((price) => {
            this.store.dispatch(
              NetcoreActions.handlePlanView({
                price,
              }),
            );
          }),
        )
        .subscribe(),
    );
  }

  protected clearSubscriptions() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  @HostListener('window:beforeunload', ['$event'])
  protected onClose() {
    this.handleRemoveGamefyTrial();
  }

  @HostListener('window:pagehide', ['$event'])
  protected onCloseIos() {
    this.handleRemoveGamefyTrial();
  }

  protected assignPrizeUuid() {
    this.prizeUuid = this.store.pipe(select(selectPlanPrizeByUuid(this.product.variantUuid)));
  }

  protected dispatchHandleAccessToCheckout(planPath: string, variantUuid: string) {
    const isOffer = this.route.snapshot.queryParamMap.get('offer') !== null;

    this.store.dispatch(
      PaymentInfoActions.handleAccessToCheckout({
        planPath,
        variantUuid,
        isOffer,
      }),
    );
  }

  public getProductPlanTitle() {
    if (this.isExpressApproval()) {
      return this.translateService.instant('modules.main.pages.payment.product_types.express_approval');
    }

    const subscriptionType = MembershipCommon.getMachineMembership(this.product.identifier);

    return this.translateService.instant(`modules.main.pages.payment.product_types.${subscriptionType}_member`);
  }

  public isExpressApproval() {
    return this.product.productUuid === ProductIdentifier.EXPRESS_APPROVAL_PRODUCT_UUID;
  }

  public isElite() {
    return this.product.identifier === MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE;
  }

  public isTrial() {
    return this.trialService.isTrialProduct(this.product.variantUuid);
  }

  protected setPrice() {
    this.price = this.getPriceByMembershipAndPeriod().pipe(
      map((price: number | undefined) => {
        if (this.isExpressApproval()) {
          return this.EXPRESS_APPROVAL_PRICE;
        }

        if (this.isTrial()) {
          return this.getTrialPrice();
        }

        if (price === undefined) {
          return 0;
        }

        return price;
      }),
    );
  }

  protected getPriceByMembershipAndPeriod() {
    return this.store.pipe(
      select(selectOptionsByMembershipAndPeriod(this.product.identifier, this.product.periodCode)),
      map((paymentOption) => {
        return paymentOption?.price || 0;
      }),
    );
  }

  protected getTrialPrice() {
    if (!Trial.getEnabledTrial(this.product.periodCode)) {
      return 0;
    }

    return Trial.getEnabledTrial(this.product.periodCode).price.toNumber();
  }

  protected handleRemoveGamefyTrial() {
    if (!this.isTrial()) {
      return;
    }

    this.store.dispatch(
      TrialActions.setGameTrial({
        period: null,
      }),
    );
  }

  public getColClasses() {
    if (this.isExpressApproval()) {
      return 'no-left-margin col-md-5';
    }

    return 'col-md-6';
  }

  public canShowMobileTestimonials() {
    return ScreenSizeHelpers.isMobile() && this.canSeeTestimonials();
  }

  public canShowDesktopTestimonials() {
    return ScreenSizeHelpers.isMediumScreenOrLarger() && this.canSeeTestimonials();
  }

  protected canSeeTestimonials() {
    return this.isDaddyMommy() && !this.isExpressApproval();
  }

  protected isDaddyMommy() {
    return MembershipCommon.isDaddyMommyType(this.product.identifier);
  }

  public setInstallments(emittedValue: number): void {
    this.installments = emittedValue;
  }

  public isCardPayment() {
    return this.currentPaymentMethod === PaymentMethods.PAYMENT_CREDIT_CARD;
  }

  public isBoletoPayment() {
    return this.currentPaymentMethod === PaymentMethods.PAYMENT_BOLETO;
  }

  public isPixPayment(hasPixTest: boolean) {
    if (!this.isExpressApproval()) {
      return this.canShowPixOnMembership(hasPixTest);
    }

    return this.canShowPixOnExpressApproval();
  }

  public canShowPixOnExpressApproval() {
    return this.isPixPaymentEnabledExpressApproval && this.currentPaymentMethod === PaymentMethods.PAYMENT_PIX;
  }

  public canShowPixOnMembership(hasPixTest: boolean) {
    return this.isPixPaymentEnabledMembership && hasPixTest && this.currentPaymentMethod === PaymentMethods.PAYMENT_PIX;
  }

  public getProductDescription() {
    let productType = 'express_approval';

    if (!this.isExpressApproval()) {
      productType = MembershipCommon.getMachineMembership(this.product.identifier) + '_member';
    }

    const plan = this.translateService.instant(`modules.main.pages.payment.product_types.${productType}`);

    if (!this.product.periodCode) {
      return plan;
    }

    return this.translateService.instant('modules.main.pages.payment.plan_description', {
      plan,
      duration: this.getPlanDuration(),
    });
  }

  protected getPlanDuration() {
    if (Trial.isAtTrialLocation(location.href)) {
      return '';
    }

    return this.translateService.instant('modules.main.pages.payment.plan_duration.months', {
      period: this.product.periodCode,
    });
  }

  protected loadScriptPagseguro() {
    const URL = Config.pagseguroUrlScript;

    this.subscriptions.push(this.scriptLoaderService.loadScript('pagseguroCheckout', URL).pipe(take(1)).subscribe());
  }
}
