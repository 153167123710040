<mp-actionbar>
    <mp-actionbar-back-title [title]="productDescription"></mp-actionbar-back-title>
</mp-actionbar>
<main>
    <div>
        <form
            class="page-form payment-form boleto-payment-form"
            (submit)="makePayment()"
        >
            <div class="form-field">
                <label for="cpf">
                    {{ 'modules.main.pages.payment.cpf' | translate }}
                </label>
                <input
                    placeholder="___.___.___-__"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    id="cpf"
                    [(ngModel)]="cpf"
                    [ngModelOptions]="{standalone: true}"
                    mask="000.000.000-00"
                >
            </div>
            <div
                *ngIf="(boletoUrlToCopy | async) as boletoUrl"
                class="form-group"
            >
                <p class="small">
                    <strong>
                        <mp-advanced-translate key="modules.main.pages.payment-boleto.download_boleto">
                            <a
                                [href]="boletoUrl"
                                mp-translate-param="link"
                                target="_blank"
                                download="download"
                            >
                                {{ 'modules.main.pages.payment-boleto.download_boleto.link' | translate }}
                            </a>
                        </mp-advanced-translate>
                    </strong>
                </p>
            </div>
            <div class="form-group">
                @if (!(isLoading | async) && !(boletoUrlToCopy | async)) {
                    <mp-submit-button
                        [text]="'modules.main.pages.payment.generate_boleto' | translate"
                        [shouldDisable]="(isLoading | async) || !cpf"
                    ></mp-submit-button>
                }
                @if ((isLoading | async)) {
                    <mp-payment-loading
                        [loadingText]="'common.payment.processing.boleto'"
                    ></mp-payment-loading>
                }
                <button
                    *ngIf="boletoUrlToCopy | async as boletoUrl"
                    class="btn boleto-url"
                    type="button"
                    (click)="copyToClipboard(boletoUrl)"
                >
                    {{ 'modules.main.pages.payment.generate_boleto.copy_to_clipboard' | translate }}
                    <span
                        class="material-icons"
                        aria-hidden="true"
                    >
                        &#xE14D;
                    </span>
                </button>
            </div>
            <p class="tw-text-xl tw-text-neutral-600">
                @if (!isExpressApproval) {
                    {{ 'modules.main.pages.payment.blurb4' | translate }}
                }
                {{ 'modules.main.pages.payment.express_approval.blurb1' | translate }}
            </p>
        </form>
    </div>
</main>
