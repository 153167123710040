@if ((hasResolvedPlans$ | async) && (hasLoadedPaymentProvider$ | async)) {
  <ng-container>
    <div class="payment-v2 payment-checkout container" *ngIf="user$ | async as self">
        <div class="row payment-v2__container" [ngClass]="getContainerModifierClass()">
            <div class="col-xs-12" [ngClass]="getColClasses()">
                <mp-payment-header
                    [plan]="getProductPlanTitle()"
                    [isTrial]="isTrial()"
                    [isExpressApproval]="isExpressApproval()"
                    [period]="product.periodCode"
                    [installments]="installments"
                    [price]="price | async"
                    [trialPrice]="getTrialPrice()"
                    [prizeUuid]="prizeUuid | async"
                ></mp-payment-header>
                <mp-payment-nagbar-v2></mp-payment-nagbar-v2>
                <mp-payment-method-selector
                    [isTrial]="isTrial()"
                    [user]="self"
                    [isExpressApproval]="isExpressApproval()"
                    [(currentPaymentMethod)]="currentPaymentMethod"
                ></mp-payment-method-selector>
                <div class="payment-container" *ngIf="(price | async) as priceValue">
                    <mp-card-payment-form
                        *ngIf="isCardPayment()"
                        [isExpressApproval]="isExpressApproval()"
                        [type]="product.identifier"
                        [period]="product.periodCode"
                        [price]="priceValue"
                        [productUuid]="product.productUuid"
                        [variantUuid]="product.variantUuid"
                        [productDescription]="getProductDescription()"
                        (selectInstallments)="setInstallments($event)"
                    ></mp-card-payment-form>
                    <mp-boleto-payment-form
                        *ngIf="isBoletoPayment()"
                        [isExpressApproval]="isExpressApproval()"
                        [type]="product.identifier"
                        [period]="product.periodCode"
                        [product]="product"
                        [price]="priceValue"
                        [productDescription]="getProductDescription()"
                    ></mp-boleto-payment-form>
                    <mp-pix-payment-form
                        *ngIf="isPixPayment(hasPixTest$ | async)"
                        [type]="product.identifier"
                        [period]="product.periodCode"
                        [productUuid]="product.productUuid"
                        [variantUuid]="product.variantUuid"
                        [productDescription]="getProductDescription()"
                    >
                    </mp-pix-payment-form>
                    <lib-payment-security-warnings
                        class="tw-mt-8"
                        [billDescription]="BILL_DESCRIPTION"
                    />
                </div>
                <mp-coupon *ngIf="!isExpressApproval()"></mp-coupon>
                <ng-container *ngIf="canShowMobileTestimonials()">
                    <mp-testimonials></mp-testimonials>
                </ng-container>
                <mp-payment-additional-information
                    [isExpressApprovalCheckout]="isExpressApproval()"
                    [isEliteCheckout]="isElite()"
                ></mp-payment-additional-information>
            </div>
            <ng-container *ngIf="canShowDesktopTestimonials()">
                <div class="col-md-6 col-xs-12 testimonials">
                    <mp-testimonials></mp-testimonials>
                </div>
            </ng-container>
        </div>
    </div>
  </ng-container>
} @else {
  <div class="payment-component-loader-container">
    <mp-loading></mp-loading>
  </div>
}
