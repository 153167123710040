import { createAction, props } from '@ngrx/store';

import { IPushCheckoutGtmEventActionPayload } from '@libs/store/upgrade-account/interfaces/push-checkout-gtm-event.interface';
import { ISavePaymentOptionsAction } from '@libs/store/upgrade-account/interfaces/save-payment-options-action.interface';
import { ISetDefaultSelectedPlansActionPayload } from '@libs/store/upgrade-account/interfaces/set-default-selected-plans.interface';
import { IUpgradeAccountActionPayload } from '@libs/store/upgrade-account/interfaces/upgrade-account-action-payload.interface';

enum Actions {
  HANDLE_UPGRADE_ACCOUNT_ACCESS = '[Upgrade Account] - Handle Upgrade Account Access',
  HANDLE_UPGRADE_ACCOUNT_VISIBLE_PLANS = '[Upgrade Account] - Handle Upgrade Account Visible Plans',
  SET_IS_RESOLVING_VISIBLE_PLANS = '[Upgrade Account] - Set Has Resolved Visible Plans',
  SET_ACTIVE_VERSION = '[Upgrade Account] - Set Active Version',
  FETCH_UPGRADE_ACCOUNT_PAYMENT_OPTIONS = '[Upgrade Account] - Fetch Payment Options',
  SAVE_PAYMENT_OPTIONS = '[Upgrade Account] - Save Payment Options',
  SET_SELECTED_PLAN = '[Upgrade Account] - Set Selected Plan',
  HANDLE_RESOLVE_LOADING = '[Upgrade Account] - Handle Resolve Loading',
  TOGGLE_VERSION = '[Upgrade Account] - Toggle Version',
  HANDLE_FETCH_LEGACY_PAYMENT_OPTIONS = '[Upgrade Account] - Handle Fetch Legacy Payment Options',
  HANDLE_FETCH_STANDARD_PAYMENT_OPTIONS = '[Upgrade Account] - Handle Fetch Standard Payment Options',
  SET_DEFAULT_SELECTED_PLANS = '[Upgrade Account] - Set Default Selected Plans',
  RESOLVE_LOADING_AFTER_FETCH_PAYMENT_OPTIONS = '[Upgrade Account] - Resolve Loading After Fetch Payment Options',
  PUSH_CHECKOUT_GTM_EVENT = '[Upgrade Account] - Push Checkout GTM Event',
  FETCH_NEW_PLANS_EXPIRED_USERS = '[Upgrade Account] - Fetch New Plans Expired Users',
  FETCH_NEW_PLANS_FREE_USERS = '[Upgrade Account] - Fetch New Plans Free Users',
  FETCH_NEW_PLANS = '[Upgrade Account] - Fetch New Plans',
}

export const handleUpgradeAccountAccess = createAction(
  Actions.HANDLE_UPGRADE_ACCOUNT_ACCESS,
  props<{ isOffer: boolean }>(),
);

export const handleUpgradeAccountVisiblePlans = createAction(
  Actions.HANDLE_UPGRADE_ACCOUNT_VISIBLE_PLANS,
  props<IUpgradeAccountActionPayload>(),
);

export const setIsResolvingVisiblePlans = createAction(
  Actions.SET_IS_RESOLVING_VISIBLE_PLANS,
  props<{ isResolvingVisiblePlans: boolean }>(),
);

export const setActiveVersion = createAction(Actions.SET_ACTIVE_VERSION, props<IUpgradeAccountActionPayload>());

export const fetchSpecificVersionPaymentOptions = createAction(
  Actions.FETCH_UPGRADE_ACCOUNT_PAYMENT_OPTIONS,
  props<IUpgradeAccountActionPayload>(),
);

export const savePaymentOptions = createAction(Actions.SAVE_PAYMENT_OPTIONS, props<ISavePaymentOptionsAction>());

export const setSelectedPlan = createAction(
  Actions.SET_SELECTED_PLAN,
  props<{
    planUuid: string;
    membershipTypeId: number;
  }>(),
);

export const handleResolveLoading = createAction(Actions.HANDLE_RESOLVE_LOADING, props<IUpgradeAccountActionPayload>());

export const toggleVersion = createAction(Actions.TOGGLE_VERSION, props<IUpgradeAccountActionPayload>());

export const handleFetchLegacyPaymentOptions = createAction(Actions.HANDLE_FETCH_LEGACY_PAYMENT_OPTIONS);

export const handleFetchStandardPaymentOptions = createAction(Actions.HANDLE_FETCH_STANDARD_PAYMENT_OPTIONS);

export const setDefaultSelectedPlans = createAction(
  Actions.SET_DEFAULT_SELECTED_PLANS,
  props<ISetDefaultSelectedPlansActionPayload>(),
);

export const resolveLoadingAfterFetchPaymentOptions = createAction(
  Actions.RESOLVE_LOADING_AFTER_FETCH_PAYMENT_OPTIONS,
  props<IUpgradeAccountActionPayload>(),
);

export const pushCheckoutGTMEvent = createAction(
  Actions.PUSH_CHECKOUT_GTM_EVENT,
  props<IPushCheckoutGtmEventActionPayload>(),
);

export const fetchNewPlansExpiredUsers = createAction(Actions.FETCH_NEW_PLANS_EXPIRED_USERS);

export const fetchNewPlansFreeUsers = createAction(Actions.FETCH_NEW_PLANS_FREE_USERS);

export const fetchNewPlans = createAction(Actions.FETCH_NEW_PLANS);
